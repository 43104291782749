import { message } from 'antd'
import toast from 'react-hot-toast'
import { checkRentalPriceOption } from '../utils/data'

export const handleApiSuccess = response => {
  if (response) {
    const { data } = response
    const successMessage = data.message || 'Operation successful!'
    toast.success(successMessage)
  } else {
    toast.success('Succeeded')
  }
}
export const handleApiError = error => {
  if (error.response) {
    const { data } = error.response
    const errorMessage = data.error || data.message || 'Server Error'
    message.error(`${errorMessage}`)
  } else if (error.request) {
    message.error('Network error, please try again later.')
  } else if (error.errorFields) {
    const errorFields = error.errorFields
    Object.keys(errorFields).forEach(key => {
      const errorMessages = errorFields[key].errors
      errorMessages.forEach(errorMessage => message.error(`${errorMessage}`))
    })
  } else {
    message.error(`Error: ${error.message}`)
  }
}

export const checkStatus = status => {
  switch (status) {
    case 'Pending':
      return 'status-pending'
    case 'Approved':
      return 'status-approved'
    case 'Active':
      return 'status-approved'
    case 'Rejected':
      return 'status-rejected'
    case 'Draft':
      return 'status-draft'
    case 'Finalized':
      return 'status-finalized'
    default:
      return 'status-na'
  }
}

export const checkLeadStatusContact = status => {
  switch (status) {
    case 'New':
      return 'styled-select status-new'
    case 'Hot':
      return 'styled-select status-hot'
    case 'Warm':
      return 'styled-select status-warm'
    case 'Cold':
      return 'styled-select status-cold'
    default:
      return 'styled-select status-default'
  }
}

export const checkInvoiceStatus = status => {
  switch (status) {
    case 'Pending Payment':
      return 'status-invoice-tag status-invoice-pending'
    case 'Paid':
      return 'status-invoice-tag status-invoice-paid'
    case 'Overdue':
      return 'status-invoice-tag status-invoice-pending'
    case 'Overpaid':
      return 'status-invoice-tag status-invoice-pending'
    case 'Partially Paid':
      return 'status-invoice-tag status-invoice-partially-paid'
    default:
      return 'status-invoice-tag status-invoice-pending'
  }
}

export const numberFormatter = value => {
  if (!value) return ''
  const numericValue = value.replace(/[^\d.]/g, '')
  const formattedValue = `${parseFloat(numericValue).toLocaleString('en-SG')}`
  return formattedValue
}

export const numberParser = value => {
  const numericValue = value.replace(/S\$|(,*)/g, '')
  if (numericValue === '') {
    return 0
  } else {
    return parseFloat(numericValue)
  }
}

export function numberToWords(num) {
  const units = [
    '',
    'One',
    'Two',
    'Three',
    'Four',
    'Five',
    'Six',
    'Seven',
    'Eight',
    'Nine',
  ]
  const teens = [
    'Ten',
    'Eleven',
    'Twelve',
    'Thirteen',
    'Fourteen',
    'Fifteen',
    'Sixteen',
    'Seventeen',
    'Eighteen',
    'Nineteen',
  ]
  const tens = [
    '',
    '',
    'Twenty',
    'Thirty',
    'Forty',
    'Fifty',
    'Sixty',
    'Seventy',
    'Eighty',
    'Ninety',
  ]

  if (num === 0) return 'Zero'

  let words = ''

  if (num >= 1000) {
    words += numberToWords(Math.floor(num / 1000)) + ' Thousand '
    num %= 1000
  }

  if (num >= 100) {
    words += units[Math.floor(num / 100)] + ' Hundred '
    num %= 100
  }

  if (num >= 20) {
    words += tens[Math.floor(num / 10)] + ' '
    num %= 10
  }

  if (num >= 10) {
    words += teens[num - 10] + ' '
    num = 0
  }

  if (num > 0) {
    words += units[num] + ' '
  }

  return words.trim()
}

export const formatNumberDigits = amount => {
  if (isNaN(amount)) return 'Invalid Number'

  const roundedAmount = parseFloat(amount).toFixed(2)
  const parts = roundedAmount.split('.')
  let integerPart = parts[0]
  let decimalPart = parts[1]
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  let formattedAmount = integerPart
  if (decimalPart) {
    formattedAmount += '.' + decimalPart
  }

  return formattedAmount
}

export function formatRelativeTime(date) {
  const now = new Date()
  const difference = now - new Date(date)
  const seconds = Math.floor(difference / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const weeks = Math.floor(days / 7)
  const months = Math.floor(days / 30)
  const years = Math.floor(days / 365)

  if (seconds < 60) {
    return `${seconds} seconds ago`
  } else if (minutes < 60) {
    return `${minutes} minutes ago`
  } else if (hours < 24) {
    return `${hours} hours ago`
  } else if (days < 7) {
    return `${days} days ago`
  } else if (weeks < 4) {
    return `${weeks} weeks ago`
  } else if (months < 12) {
    return `${months} months ago`
  } else {
    return `${years} years ago`
  }
}

export function formatPhoneNumber(phoneNumber) {
  const cleaned = phoneNumber.replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }

  // If no match, return the original input (could not format)

  return phoneNumber
}

export const calculateAndSetGrossTaxAmount = (
  initialValues,
  invoiceForm,
  name,
  value
) => {
  let grossTaxAmount = 0

  if (initialValues && initialValues.rentalPrice) {
    grossTaxAmount = value / parseInt(initialValues.rentalPrice)
  } else if (initialValues && initialValues.salePrice) {
    grossTaxAmount = (value / parseInt(initialValues.salePrice)) * 100
  } else {
    grossTaxAmount = (value / parseInt(initialValues.transactionAmount)) * 100
  }

  if (grossTaxAmount > 0) {
    invoiceForm.setFieldsValue({
      invoices: invoiceForm.getFieldValue('invoices').map((invoice, index) => {
        if (index === name) {
          return {
            ...invoice,
            grossTaxAmount: grossTaxAmount.toFixed(2),
          }
        }
        return invoice
      }),
    })
  }
}
export const calculateAndSetGrossCommissionAmount = (
  initialValues,
  invoiceForm,
  name,
  value
) => {
  let grossCommission = 0
  if (initialValues && initialValues.rentalPrice) {
    grossCommission = value * parseInt(initialValues.rentalPrice)
  } else if (initialValues && initialValues.salePrice) {
    grossCommission = (value * parseInt(initialValues.salePrice)) / 100
  } else {
    grossCommission = (value / 100) * parseInt(initialValues.transactionAmount)
  }

  if (grossCommission > 0) {
    invoiceForm.setFieldsValue({
      invoices: invoiceForm.getFieldValue('invoices').map((invoice, index) => {
        if (index === name) {
          return {
            ...invoice,
            grossCommission: grossCommission,
          }
        }
        return invoice
      }),
    })
  }
}

export const generateLabelInvoice = (selectedType, initialValues) => {
  if (selectedType === 'SALE' || selectedType === 'PROJECT_SALE') {
    return `% of Sale Price (${initialValues?.currency} $${
      initialValues?.salePrice ?? 0
    })`
  } else if (
    selectedType === 'BANK_REFERRAL' ||
    selectedType === 'PROPERTY_MANAGEMENT' ||
    selectedType === 'OTHER'
  ) {
    return ` % of Transaction Amount ($ ${initialValues?.currency} ${
      initialValues?.transactionAmount
        ? initialValues.transactionAmount
        : 'Not entered yet'
    })`
  } else {
    return `${checkRentalPriceOption(
      initialValues?.rentalPriceOption
    )}(s) of rent (Rental Price: ${initialValues?.currency} $${
      initialValues?.rentalPrice
    })`
  }
}

export const formatKey = key => {
  return key
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/_/g, ' ')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}
