import { Link } from 'react-router-dom'
import { IconLogout, IconUserSquare, icons } from '@tabler/icons-react'
import { Typography } from 'antd'

export const typeData = [
  {
    label: <span>Sale</span>,
    title: 'Sale',
    options: [
      {
        label: <span>Sale</span>,
        value: 'SALE',
      },
      {
        label: <span>Project Sale</span>,
        value: 'PROJECT_SALE',
      },
    ],
  },
  {
    label: <span>Rental</span>,
    title: 'Rental',
    options: [
      {
        label: <span>Rental</span>,
        value: 'RENTAL',
      },
      {
        label: <span>Assignment of Lease</span>,
        value: 'ASSIGNMENT_OF_LEASE',
      },
      {
        label: <span>Serviced Apartment</span>,
        value: 'SERVICED_APARTMENT',
      },
    ],
  },
  {
    label: <span>Referral</span>,
    title: 'Referral',
    options: [
      {
        label: <span>Bank Referral</span>,
        value: 'BANK_REFERRAL',
      },
    ],
  },
  {
    label: <span>Other</span>,
    title: 'Other',
    options: [
      {
        label: <span>Property Management</span>,
        value: 'PROPERTY_MANAGEMENT',
      },
      {
        label: <span>Other</span>,
        value: 'OTHER',
      },
    ],
  },
]

export const propertTypeData = [
  { label: 'Apartment/Condo', value: 'Apartment/Condo' },
  {
    label: 'Apartment/Condo > Condominium',
    value: 'Apartment/Condo > Condominium',
  },
  {
    label: 'Apartment/Condo > Apartment',
    value: 'Apartment/Condo > Apartment',
  },
  {
    label: 'Apartment/Condo > Walk-up',
    value: 'Apartment/Condo > Walk-up',
  },
  {
    label: 'Apartment/Condo > Executive Condominium',
    value: 'Apartment/Condo > Executive Condominium',
  },
  {
    label: 'Apartment/Condo > Penthouse',
    value: 'Apartment/Condo > Penthouse',
  },
  { label: 'Apartment/Condo > Duplex', value: 'Apartment/Condo > Duplex' },
  {
    label: 'Apartment/Condo > Triplex',
    value: 'Apartment/Condo > Triplex',
  },
  {
    label: 'Apartment/Condo > Serviced Residence',
    value: 'Apartment/Condo > Serviced Residence',
  },
  { label: 'Landed House', value: 'Landed House' },
  {
    label: 'Landed House > Cluster House',
    value: 'Landed House > Cluster House',
  },
  {
    label: 'Landed House > Terraced House',
    value: 'Landed House > Terraced House',
  },
  {
    label: 'Landed House > Detached House',
    value: 'Landed House > Detached House',
  },
  {
    label: 'Landed House > Semi-Detached House',
    value: 'Landed House > Semi-Detached House',
  },
  {
    label: 'Landed House > Corner Terrace',
    value: 'Landed House > Corner Terrace',
  },
  {
    label: 'Landed House > Bungalow House',
    value: 'Landed House > Bungalow House',
  },
  {
    label: 'Landed House > Good Class Bungalow',
    value: 'Landed House > Good Class Bungalow',
  },
  { label: 'Landed House > Shophouse', value: 'Landed House > Shophouse' },
  {
    label: 'Landed House > Town House',
    value: 'Landed House > Town House',
  },
  {
    label: 'Landed House > Conservation House',
    value: 'Landed House > Conservation House',
  },
  { label: 'Landed House > Duplex', value: 'Landed House > Duplex' },
  { label: 'HDB Apartment', value: 'HDB Apartment' },
  {
    label: 'HDB Apartment > 2-room flats',
    value: 'HDB Apartment > 2-room flats',
  },
  {
    label: 'HDB Apartment > 2-room flats > 2I (improved)',
    value: 'HDB Apartment > 2-room flats > 2I (improved)',
  },
  {
    label: 'HDB Apartment > 2-room flats > 2S (Standard)',
    value: 'HDB Apartment > 2-room flats > 2S (Standard)',
  },
  {
    label: 'HDB Apartment > 3-room flats',
    value: 'HDB Apartment > 3-room flats',
  },
  {
    label: 'HDB Apartment > 3-room flats > 3A',
    value: 'HDB Apartment > 3-room flats > 3A',
  },
  {
    label: 'HDB Apartment > 3-room flats > 3NG (New Generation)',
    value: 'HDB Apartment > 3-room flats > 3NG (New Generation)',
  },
  {
    label: 'HDB Apartment > 3-room flats > 3A (Modified)',
    value: 'HDB Apartment > 3-room flats > 3A (Modified)',
  },
  {
    label: 'HDB Apartment > 3-room flats > 3NG (Modified)',
    value: 'HDB Apartment > 3-room flats > 3NG (Modified)',
  },
  {
    label: 'HDB Apartment > 3-room flats > 3I (Improved)',
    value: 'HDB Apartment > 3-room flats > 3I (Improved)',
  },
  {
    label: 'HDB Apartment > 3-room flats > 3I (Modified)',
    value: 'HDB Apartment > 3-room flats > 3I (Modified)',
  },
  {
    label: 'HDB Apartment > 3-room flats > 3S (Simplified)',
    value: 'HDB Apartment > 3-room flats > 3S (Simplified)',
  },
  {
    label: 'HDB Apartment > 3-room flats > 3STD (Standard)',
    value: 'HDB Apartment > 3-room flats > 3STD (Standard)',
  },
  {
    label: 'HDB Apartment > 4-room flats',
    value: 'HDB Apartment > 4-room flats',
  },
  {
    label: 'HDB Apartment > 4-room flats > 4A',
    value: 'HDB Apartment > 4-room flats > 4A',
  },
  {
    label: 'HDB Apartment > 4-room flats > 4NG (New Generation)',
    value: 'HDB Apartment > 4-room flats > 4NG (New Generation)',
  },
  {
    label: 'HDB Apartment > 4-room flats > 4S ( Simplified)',
    value: 'HDB Apartment > 4-room flats > 4S ( Simplified)',
  },
  {
    label: 'HDB Apartment > 4-room flats > 4I ( Improved)',
    value: 'HDB Apartment > 4-room flats > 4I ( Improved)',
  },
  {
    label: 'HDB Apartment > 4-room flats > 4STD (Standard)',
    value: 'HDB Apartment > 4-room flats > 4STD (Standard)',
  },
  {
    label: 'HDB Apartment > 5-room flats',
    value: 'HDB Apartment > 5-room flats',
  },
  {
    label: 'HDB Apartment > 5-room flats > 5A',
    value: 'HDB Apartment > 5-room flats > 5A',
  },
  {
    label: 'HDB Apartment > 5-room flats > 5I',
    value: 'HDB Apartment > 5-room flats > 5I',
  },
  {
    label: 'HDB Apartment > 5-room flats > 5S',
    value: 'HDB Apartment > 5-room flats > 5S',
  },
  {
    label: 'HDB Apartment > 6-room flats',
    value: 'HDB Apartment > 6-room flats',
  },
  {
    label: 'HDB Apartment > 6-room flats > Jumbo',
    value: 'HDB Apartment > 6-room flats > Jumbo',
  },
  {
    label: 'HDB Apartment > Executive flats',
    value: 'HDB Apartment > Executive flats',
  },
  {
    label: 'HDB Apartment > Executive flats > EA (Exec Apartment)',
    value: 'HDB Apartment > Executive flats > EA (Exec Apartment)',
  },
  {
    label: 'HDB Apartment > Executive flats > EM (Exec Maisonette)',
    value: 'HDB Apartment > Executive flats > EM (Exec Maisonette)',
  },
  {
    label: 'HDB Apartment > Executive flats > MG (Multi-Generation)',
    value: 'HDB Apartment > Executive flats > MG (Multi-Generation)',
  },
  {
    label: 'HDB Apartment > Executive flats > Terrace',
    value: 'HDB Apartment > Executive flats > Terrace',
  },
  {
    label: 'HDB Apartment > Executive flats > Premium Apartment',
    value: 'HDB Apartment > Executive flats > Premium Apartment',
  },
  {
    label: 'HDB Apartment > Executive flats > Adjoined Flat',
    value: 'HDB Apartment > Executive flats > Adjoined Flat',
  },
  {
    label: 'HDB Apartment > Executive flats > Model A Maisonette',
    value: 'HDB Apartment > Executive flats > Model A Maisonette',
  },
  {
    label: 'HDB Apartment > Executive flats > HUDC',
    value: 'HDB Apartment > Executive flats > HUDC',
  },
  {
    label: 'HDB Apartment > 1-Room/Studio',
    value: 'HDB Apartment > 1-Room/Studio',
  },
  { label: 'Residential Land', value: 'Residential Land' },
  { label: 'Other Residential', value: 'Other Residential' },

  { label: 'Industrial', value: 'Industrial' },
  {
    label: 'Industrial > Light Industrial (B1)',
    value: 'Industrial > Light Industrial (B1)',
  },
  {
    label: 'Industrial > Factory / Workshop (B2)',
    value: 'Industrial > Factory / Workshop (B2)',
  },
  {
    label: 'Industrial > Factory / Workshop (B2) > Food Factory',
    value: 'Industrial > Factory / Workshop (B2) > Food Factory',
  },
  { label: 'Industrial > Warehouse', value: 'Industrial > Warehouse' },
  { label: 'Industrial > Dormitory', value: 'Industrial > Dormitory' },
  {
    label: 'Industrial > Other Industrial',
    value: 'Industrial > Other Industrial',
  },
  { label: 'Commercial Land', value: 'Commercial Land' },
  {
    label: 'Commercial Land > Land Only',
    value: 'Commercial Land > Land Only',
  },
  {
    label: 'Commercial Land > Land with Building / En-bloc',
    value: 'Commercial Land > Land with Building / En-bloc',
  },
  { label: 'Retail', value: 'Retail' },
  { label: 'Retail > Mall Shop', value: 'Retail > Mall Shop' },
  {
    label: 'Retail > Shop / Shophouse',
    value: 'Retail > Shop / Shophouse',
  },
  { label: 'Retail > Food & Beverage', value: 'Retail > Food & Beverage' },
  {
    label: 'Retail > Food & Beverage > Restaurant',
    value: 'Retail > Food & Beverage > Restaurant',
  },
  {
    label: 'Retail > Food & Beverage > Pubs',
    value: 'Retail > Food & Beverage > Pubs',
  },
  {
    label: 'Retail > Food & Beverage > Eating House',
    value: 'Retail > Food & Beverage > Eating House',
  },
  {
    label: 'Retail > Food & Beverage > Canteen',
    value: 'Retail > Food & Beverage > Canteen',
  },
  {
    label: 'Retail > Food & Beverage > Food Court',
    value: 'Retail > Food & Beverage > Food Court',
  },
  {
    label: 'Retail > Food & Beverage > Cafe',
    value: 'Retail > Food & Beverage > Cafe',
  },
  {
    label: 'Retail > Food & Beverage > Kiosk',
    value: 'Retail > Food & Beverage > Kiosk',
  },
  {
    label: 'Retail > Food & Beverage > Food Stall',
    value: 'Retail > Food & Beverage > Food Stall',
  },
  { label: 'Retail > Medical', value: 'Retail > Medical' },
  { label: 'Retail > Other Retail', value: 'Retail > Other Retail' },
  { label: 'Office', value: 'Office' },
  { label: 'Office > Office', value: 'Office > Office' },
  {
    label: 'Office > Business / Science Park',
    value: 'Office > Business / Science Park',
  },
  { label: 'Other Commercial', value: 'Other Commercial' },

  { label: 'Mixed Development', value: 'Mixed Development' },
]

export const currenyData = [
  { label: 'SGD - Singapore Dollar', value: 'SGD' },
  { label: 'AED - United Arab Emirates Dirham', value: 'AED' },
  { label: 'AFN - Afghan Afghani', value: 'AFN' },
  { label: 'ALL - Albanian Lek', value: 'ALL' },
  { label: 'AMD - Armenian Dram', value: 'AMD' },
  { label: 'ANG - Netherlands Antillean Guilder', value: 'ANG' },
  { label: 'AOA - Angolan Kwanza', value: 'AOA' },
  { label: 'ARS - Argentine Peso', value: 'ARS' },
  { label: 'AUD - Australia Dollar', value: 'AUD' },
  { label: 'AWG - Aruban Florin', value: 'AWG' },
  { label: 'AZN - Azerbaijani Manat', value: 'AZN' },
  { label: 'BAM - Bosnia-Herzegovina Convertible Mark', value: 'BAM' },
  { label: 'BBD - Barbadian Dollar', value: 'BBD' },
  { label: 'BDT - Bangladeshi Taka', value: 'BDT' },
  { label: 'BGN - Bulgarian Lev', value: 'BGN' },
  { label: 'BHD - Bahraini Dinar', value: 'BHD' },
  { label: 'BIF - Burundian Franc', value: 'BIF' },
  { label: 'BMD - Bermudan Dollar', value: 'BMD' },
  { label: 'BND - Brunei Dollar', value: 'BND' },
  { label: 'BOB - Bolivian Boliviano', value: 'BOB' },
  { label: 'BRL - Brazilian Real', value: 'BRL' },
  { label: 'BWP - Botswanan Pula', value: 'BWP' },
  { label: 'BYR - Belarusian Ruble', value: 'BYR' },
  { label: 'BZD - Belize Dollar', value: 'BZD' },
  { label: 'CAD - Canadian Dollar', value: 'CAD' },
  { label: 'CDF - Congolese Franc', value: 'CDF' },
  { label: 'CHF - Swiss Franc', value: 'CHF' },
  { label: 'CLP - Chilean Peso', value: 'CLP' },
  { label: 'CNY - Chinese Yuan', value: 'CNY' },
  { label: 'COP - Colombian Peso', value: 'COP' },
  { label: 'CRC - Costa Rican Colón', value: 'CRC' },
  { label: 'CVE - Cape Verdean Escudo', value: 'CVE' },
  { label: 'CZK - Czech Republic Koruna', value: 'CZK' },
  { label: 'DJF - Djiboutian Franc', value: 'DJF' },
  { label: 'DKK - Danish Krone', value: 'DKK' },
  { label: 'DOP - Dominican Peso', value: 'DOP' },
  { label: 'DZD - Algerian Dinar', value: 'DZD' },
  { label: 'EGP - Egyptian Pound', value: 'EGP' },
  { label: 'ERN - Eritrean Nakfa', value: 'ERN' },
  { label: 'ETB - Ethiopian Birr', value: 'ETB' },
  { label: 'EUR - Euro', value: 'EUR' },
  { label: 'GBP - British Pound', value: 'GBP' },
  { label: 'GEL - Georgian Lari', value: 'GEL' },
  { label: 'GHS - Ghanaian Cedi', value: 'GHS' },
  { label: 'GNF - Guinean Franc', value: 'GNF' },
  { label: 'GTQ - Guatemalan Quetzal', value: 'GTQ' },
  { label: 'GYD - Guyanaese Dollar', value: 'GYD' },
  { label: 'HKD - Hong Kong Dollar', value: 'HKD' },
  { label: 'HNL - Honduran Lempira', value: 'HNL' },
  { label: 'HRK - Croatian Kuna', value: 'HRK' },
  { label: 'HUF - Hungarian Forint', value: 'HUF' },
  { label: 'IDR - Indonesian Rupiah', value: 'IDR' },
  { label: 'ILS - Israeli New Sheqel', value: 'ILS' },
  { label: 'INR - Indian Rupee', value: 'INR' },
  { label: 'IQD - Iraqi Dinar', value: 'IQD' },
  { label: 'IRR - Iranian Rial', value: 'IRR' },
  { label: 'ISK - Icelandic Króna', value: 'ISK' },
  { label: 'JMD - Jamaican Dollar', value: 'JMD' },
  { label: 'JOD - Jordanian Dinar', value: 'JOD' },
  { label: 'JPY - Japanese Yen', value: 'JPY' },
  { label: 'KES - Kenyan Shilling', value: 'KES' },
  { label: 'KGS - Kyrgystani Som', value: 'KGS' },
  { label: 'KHR - Cambodian Riel', value: 'KHR' },
  { label: 'KMF - Comorian Franc', value: 'KMF' },
  { label: 'KPW - North Korean Won', value: 'KPW' },
  { label: 'KRW - South Korean Won', value: 'KRW' },
  { label: 'KWD - Kuwaiti Dinar', value: 'KWD' },
  { label: 'KYD - Cayman Islands Dollar', value: 'KYD' },
  { label: 'KZT - Kazakhstani Tenge', value: 'KZT' },
  { label: 'LAK - Laotian Kip', value: 'LAK' },
  { label: 'LBP - Lebanese Pound', value: 'LBP' },
  { label: 'LKR - Sri Lankan Rupee', value: 'LKR' },
  { label: 'LRD - Liberian Dollar', value: 'LRD' },
  { label: 'LSL - Lesotho Loti', value: 'LSL' },
  { label: 'LTL - Lithuanian Litas', value: 'LTL' },
  { label: 'LVL - Latvian Lats', value: 'LVL' },
  { label: 'LYD - Libyan Dinar', value: 'LYD' },
  { label: 'MAD - Moroccan Dirham', value: 'MAD' },
  { label: 'MDL - Moldovan Leu', value: 'MDL' },
  { label: 'MGA - Malagasy Ariary', value: 'MGA' },
  { label: 'MKD - Macedonian Denar', value: 'MKD' },
  { label: 'MMK - Myanma Kyat', value: 'MMK' },
  { label: 'MNT - Mongolian Tugrik', value: 'MNT' },
  { label: 'MOP - Macanese Pataca', value: 'MOP' },
  { label: 'MRO - Mauritanian Ouguiya', value: 'MRO' },
  { label: 'MUR - Mauritian Rupee', value: 'MUR' },
  { label: 'MVR - Maldivian Rufiyaa', value: 'MVR' },
  { label: 'MWK - Malawian Kwacha', value: 'MWK' },
  { label: 'MXN - Mexican Peso', value: 'MXN' },
  { label: 'MYR - Malaysian Ringgit', value: 'MYR' },
  { label: 'MZN - Mozambican Metical', value: 'MZN' },
  { label: 'NAD - Namibian Dollar', value: 'NAD' },
  { label: 'NGN - Nigerian Naira', value: 'NGN' },
  { label: 'NIO - Nicaraguan Córdoba', value: 'NIO' },
  { label: 'NOK - Norwegian Krone', value: 'NOK' },
  { label: 'NPR - Nepalese Rupee', value: 'NPR' },
  { label: 'NZD - New Zealand Dollar', value: 'NZD' },
  { label: 'OMR - Omani Rial', value: 'OMR' },
  { label: 'PAB - Panamanian Balboa', value: 'PAB' },
  { label: 'PEN - Peruvian Nuevo Sol', value: 'PEN' },
  { label: 'PGK - Papua New Guinean Kina', value: 'PGK' },
  { label: 'PHP - Philippine Peso', value: 'PHP' },
  { label: 'PKR - Pakistani Rupee', value: 'PKR' },
  { label: 'PLN - Polish Zloty', value: 'PLN' },
  { label: 'PYG - Paraguayan Guarani', value: 'PYG' },
  { label: 'QAR - Qatari Rial', value: 'QAR' },
  { label: 'RON - Romanian Leu', value: 'RON' },
  { label: 'RSD - Serbian Dinar', value: 'RSD' },
  { label: 'RUB - Russian Ruble', value: 'RUB' },
  { label: 'RWF - Rwandan Franc', value: 'RWF' },
  { label: 'SAR - Saudi Riyal', value: 'SAR' },
  { label: 'SBD - Solomon Islands Dollar', value: 'SBD' },
  { label: 'SCR - Seychellois Rupee', value: 'SCR' },
  { label: 'SDG - Sudanese Pound', value: 'SDG' },
  { label: 'SEK - Swedish Krona', value: 'SEK' },
  { label: 'SHP - Saint Helena Pound', value: 'SHP' },
  { label: 'SLL - Sierra Leonean Leone', value: 'SLL' },
  { label: 'SOS - Somali Shilling', value: 'SOS' },
  { label: 'SRD - Surinamese Dollar', value: 'SRD' },
  { label: 'STD - São Tomé and Príncipe Dobra', value: 'STD' },
  { label: 'SVC - Salvadoran Colón', value: 'SVC' },
  { label: 'SYP - Syrian Pound', value: 'SYP' },
  { label: 'SZL - Swazi Lilangeni', value: 'SZL' },
  { label: 'THB - Thai Baht', value: 'THB' },
  { label: 'TJS - Tajikistani Somoni', value: 'TJS' },
  { label: 'TMT - Turkmenistani Manat', value: 'TMT' },
  { label: 'TND - Tunisian Dinar', value: 'TND' },
  { label: "TOP - Tongan Pa'anga", value: 'TOP' },
  { label: 'TRY - Turkish Lira', value: 'TRY' },
  { label: 'TTD - Trinidad and Tobago Dollar', value: 'TTD' },
  { label: 'TWD - New Taiwan Dollar', value: 'TWD' },
  { label: 'TZS - Tanzanian Shilling', value: 'TZS' },
  { label: 'UAH - Ukrainian Hryvnia', value: 'UAH' },
  { label: 'UGX - Ugandan Shilling', value: 'UGX' },
  { label: 'USD - United States Dollar', value: 'USD' },
  { label: 'UYU - Uruguayan Peso', value: 'UYU' },
  { label: 'UZS - Uzbekistan Som', value: 'UZS' },
  { label: 'VEF - Venezuelan Bolívar Fuerte', value: 'VEF' },
  { label: 'VND - Vietnamese Dong', value: 'VND' },
  { label: 'VUV - Vanuatu Vatu', value: 'VUV' },
  { label: 'WST - Samoan Tala', value: 'WST' },
  { label: 'XAF - CFA Franc BEAC', value: 'XAF' },
  { label: 'XCD - East Caribbean Dollar', value: 'XCD' },
  { label: 'XOF - CFA Franc BCEAO', value: 'XOF' },
  { label: 'XPF - CFP Franc', value: 'XPF' },
  { label: 'YER - Yemeni Rial', value: 'YER' },
  { label: 'ZAR - South African Rand', value: 'ZAR' },
  { label: 'ZMW - Zambian Kwacha', value: 'ZMW' },
]
export const partyOptions = [
  {
    type: 'SALE',
    options: [
      { label: 'Buyer', value: 'Buyer' },
      {
        label: "Buyer's Power of Attorney",
        value: "Buyer's Power of Attorney",
      },
      { label: "Buyer's Representative", value: "Buyer's Representative" },
      { label: "Buyer's Solicitor", value: "Buyer's Solicitor" },
      { label: 'Referrer', value: 'Referrer' },
      { label: 'Seller', value: 'Seller' },
      {
        label: "Seller's Power of Attorney",
        value: "Seller's Power of Attorney",
      },
      { label: "Seller's Representative", value: "Seller's Representative" },
      { label: "Seller's Solicitor", value: "Seller's Solicitor" },
    ],
  },
  {
    type: 'PROJECT_SALE',
    options: [
      { value: 'Assignee', label: 'Assignee' },
      { value: 'Assignor', label: 'Assignor' },
      { value: 'Buyer', label: 'Buyer' },
      {
        value: "Buyer's Power of Attorney",
        label: "Buyer's Power of Attorney",
      },
      { value: "Buyer's Representative", label: "Buyer's Representative" },
      { value: "Buyer's Solicitor", label: "Buyer's Solicitor" },
      { value: 'Landlord', label: 'Landlord' },
      {
        value: "Landlord's Power of Attorney",
        label: "Landlord's Power of Attorney",
      },
      {
        value: "Landlord's Representative",
        label: "Landlord's Representative",
      },
      { value: 'Occupant', label: 'Occupant' },
      { value: 'Referrer', label: 'Referrer' },
      { value: 'Seller', label: 'Seller' },
      {
        value: "Seller's Power of Attorney",
        label: "Seller's Power of Attorney",
      },
      { value: "Seller's Representative", label: "Seller's Representative" },
      { value: "Seller's Solicitor", label: "Seller's Solicitor" },
      { value: 'Sub-tenant', label: 'Sub-tenant' },
      {
        value: "Sub-tenant's Representative",
        label: "Sub-tenant's Representative",
      },
      { value: 'Tenant', label: 'Tenant' },
      {
        value: "Tenant's Power of Attorney",
        label: "Tenant's Power of Attorney",
      },
      { value: "Tenant's Representative", label: "Tenant's Representative" },
    ],
  },
  {
    type: 'RENTAL',
    options: [
      { label: 'Landlord', value: 'Landlord' },
      {
        label: "Landlord's Power of Attorney",
        value: "Landlord's Power of Attorney",
      },
      {
        label: "Landlord's Representative",
        value: "Landlord's Representative",
      },
      { label: 'Occupant', value: 'Occupant' },
      { label: 'Referrer', value: 'Referrer' },
      { label: 'Sub-tenant', value: 'Sub-tenant' },
      {
        label: "Sub-tenant's Representative",
        value: "Sub-tenant's Representative",
      },
      { label: 'Tenant', value: 'Tenant' },
      {
        label: "Tenant's Power of Attorney",
        value: "Tenant's Power of Attorney",
      },
      { label: "Tenant's Representative", value: "Tenant's Representative" },
    ],
  },

  {
    type: 'ASSIGNMENT_OF_LEASE',
    options: [
      { label: 'Assignee', value: 'Assignee' },
      { label: 'Assignor', value: 'Assignor' },
      { label: 'Referrer', value: 'Referrer' },
    ],
  },
  {
    type: 'SERVICED_APARTMENT',
    options: [
      { label: 'Landlord', value: 'Landlord' },
      {
        label: "Landlord's Power of Attorney",
        value: "Landlord's Power of Attorney",
      },
      {
        label: "Landlord's Representative",
        value: "Landlord's Representative",
      },
      { label: 'Occupant', value: 'Occupant' },
      { label: 'Referrer', value: 'Referrer' },
      { label: 'Sub-tenant', value: 'Sub-tenant' },
      {
        label: "Sub-tenant's Representative",
        value: "Sub-tenant's Representative",
      },
      { label: 'Tenant', value: 'Tenant' },
      {
        label: "Tenant's Power of Attorney",
        value: "Tenant's Power of Attorney",
      },
      { label: "Tenant's Representative", value: "Tenant's Representative" },
    ],
  },
  {
    type: 'BANK_REFERRAL',
    options: [
      { value: 'Assignee', label: 'Assignee' },
      { value: 'Assignor', label: 'Assignor' },
      { value: 'Buyer', label: 'Buyer' },
      {
        value: "Buyer's Power of Attorney",
        label: "Buyer's Power of Attorney",
      },
      { value: "Buyer's Representative", label: "Buyer's Representative" },
      { value: "Buyer's Solicitor", label: "Buyer's Solicitor" },
      { value: 'Landlord', label: 'Landlord' },
      {
        value: "Landlord's Power of Attorney",
        label: "Landlord's Power of Attorney",
      },
      {
        value: "Landlord's Representative",
        label: "Landlord's Representative",
      },
      { value: 'Occupant', label: 'Occupant' },
      { value: 'Referrer', label: 'Referrer' },
      { value: 'Seller', label: 'Seller' },
      {
        value: "Seller's Power of Attorney",
        label: "Seller's Power of Attorney",
      },
      { value: "Seller's Representative", label: "Seller's Representative" },
      { value: "Seller's Solicitor", label: "Seller's Solicitor" },
      { value: 'Sub-tenant', label: 'Sub-tenant' },
      {
        value: "Sub-tenant's Representative",
        label: "Sub-tenant's Representative",
      },
      { value: 'Tenant', label: 'Tenant' },
      {
        value: "Tenant's Power of Attorney",
        label: "Tenant's Power of Attorney",
      },
      { value: "Tenant's Representative", label: "Tenant's Representative" },
    ],
  },
  {
    type: 'PROPERTY_MANAGEMENT',
    options: [
      { label: 'Landlord', value: 'Landlord' },
      {
        label: "Landlord's Power of Attorney",
        value: "Landlord's Power of Attorney",
      },
      {
        label: "Landlord's Representative",
        value: "Landlord's Representative",
      },
      { label: 'Occupant', value: 'Occupant' },
      { label: 'Referrer', value: 'Referrer' },
      { label: 'Sub-tenant', value: 'Sub-tenant' },
      {
        label: "Sub-tenant's Representative",
        value: "Sub-tenant's Representative",
      },
      { label: 'Tenant', value: 'Tenant' },
      {
        label: "Tenant's Power of Attorney",
        value: "Tenant's Power of Attorney",
      },
      { label: "Tenant's Representative", value: "Tenant's Representative" },
    ],
  },
  {
    type: 'OTHER',
    options: [
      { value: 'Assignee', label: 'Assignee' },
      { value: 'Assignor', label: 'Assignor' },
      { value: 'Buyer', label: 'Buyer' },
      {
        value: "Buyer's Power of Attorney",
        label: "Buyer's Power of Attorney",
      },
      { value: "Buyer's Representative", label: "Buyer's Representative" },
      { value: "Buyer's Solicitor", label: "Buyer's Solicitor" },
      { value: 'Landlord', label: 'Landlord' },
      {
        value: "Landlord's Power of Attorney",
        label: "Landlord's Power of Attorney",
      },
      {
        value: "Landlord's Representative",
        label: "Landlord's Representative",
      },
      { value: 'Occupant', label: 'Occupant' },
      { value: 'Referrer', label: 'Referrer' },
      { value: 'Seller', label: 'Seller' },
      {
        value: "Seller's Power of Attorney",
        label: "Seller's Power of Attorney",
      },
      { value: "Seller's Representative", label: "Seller's Representative" },
      { value: "Seller's Solicitor", label: "Seller's Solicitor" },
      { value: 'Sub-tenant', label: 'Sub-tenant' },
      {
        value: "Sub-tenant's Representative",
        label: "Sub-tenant's Representative",
      },
      { value: 'Tenant', label: 'Tenant' },
      {
        value: "Tenant's Power of Attorney",
        label: "Tenant's Power of Attorney",
      },
      { value: "Tenant's Representative", label: "Tenant's Representative" },
    ],
  },
]

export const partyInitialValues = [
  { type: 'SALE', value: 'Seller' },
  { type: 'PROJECT_SALE', value: 'Landlord' },
  { type: 'RENTAL', value: 'Landlord' },
  { type: 'BANK_REFERRAL', value: 'Landlord' },
  { type: 'PROPERTY_MANAGEMENT', value: 'Landlord' },
  { type: 'ASSIGNMENT_OF_LEASE', value: 'Assignees' },
  { type: 'SERVICED_APARTMENT', value: 'Landlord' },
  { type: 'OTHER', value: 'Landlord' },
]
export const partyInitialData = selectedType => {
  return partyInitialValues.filter(item => item.type === selectedType).at(-1)
    .value
}

export const representingData = {
  SALE: [
    { label: 'Seller', value: 'seller' },
    { label: 'Buyer', value: 'buyer' },
  ],
  PROJECT_SALE: [
    { label: 'Seller', value: 'seller' },
    { label: 'Buyer', value: 'buyer' },
  ],
  RENTAL: [
    { label: 'Landlord', value: 'landlord' },
    { label: 'Tenant', value: 'tenant' },
    { label: 'Sub-tenant', value: 'sub-tenant' },
  ],
  BANK_REFERRAL: [],
  PROPERTY_MANAGEMENT: [
    { label: 'Landlord', value: 'landlord' },
    { label: 'Tenant', value: 'tenant' },
    { label: 'Sub-tenant', value: 'sub-tenant' },
  ],
  ASSIGNMENT_OF_LEASE: [
    { label: 'Assignee', value: 'Assignee' },
    { label: 'Assignor', value: 'Assignor' },
  ],
  SERVICED_APARTMENT: [
    { label: 'Landlord', value: 'landlord' },
    { label: 'Tenant', value: 'tenant' },
  ],
}

const getOptionsByType = type => {
  const data = representingData.find(item => item.type === type)
  return data ? data.options : []
}

export const rentalPriceData = [
  {
    value: 'per_week',
    label: 'Per Week',
  },
  {
    value: 'per_month',
    label: 'Per Month',
  },
  {
    value: 'per_year',
    label: 'Per Year',
  },
  {
    value: 'lump_sum',
    label: 'Lump Sum',
  },
]

export const remindOptions = [
  { value: '1_day_before', label: '1 Day Before' },
  { value: '2_days_before', label: '2 Days Before' },
  { value: '3_days_before', label: '3 Days Before' },
  { value: '1_week_before', label: '1 Week Before' },
  { value: '2_weeks_before', label: '2 Weeks Before' },
  { value: '3_weeks_before', label: '3 Weeks Before' },
  { value: '1_month_before', label: '1 Month Before' },
  { value: '2_months_before', label: '2 Months Before' },
  { value: '3_months_before', label: '3 Months Before' },
  { value: '4_months_before', label: '4 Months Before' },
  { value: '6_months_before', label: '6 Months Before' },
]
export const dateData = [
  { value: 'years', label: 'Years' },
  { value: 'months', label: 'Months' },
  { value: 'days', label: 'Days' },
]

export const salutationData = [
  { value: 'Dr', label: 'Dr' },
  { value: 'Mdm', label: 'Mdm' },
  { value: 'Miss', label: 'Miss' },
  { value: 'Mr', label: 'Mr' },
  { value: 'Mrs', label: 'Mrs' },
  { value: 'Ms', label: 'Ms' },
]

export const residencyStatus = [
  { value: 'Singapore Citizen', label: 'Singapore Citizen' },
  {
    value: 'Singapore Permanent Resident',
    label: 'Singapore Permanent Resident',
  },
  { value: 'Foreigner', label: 'Foreigner' },
]

export const leadSourceData = [
  { value: 'Referral', label: 'Referral' },
  { value: 'Website', label: 'Website' },
  { value: 'EDM', label: 'EDM' },
  { value: 'Newspapers', label: 'Newspapers' },
  { value: 'Flyers', label: 'Flyers' },
  { value: 'Online Portals', label: 'Online Portals' },
  { value: 'Social Media', label: 'Social Media' },
  { value: 'Existing Client', label: 'Existing Client' },
  { value: 'Others', label: 'Others' },
  { value: 'Event', label: 'Event' },
]

export const leadStatusdata = [
  { value: 'New', label: 'New' },
  { value: 'Hot', label: 'Hot' },
  { value: 'Warm', label: 'Warm' },
  { value: 'Cold', label: 'Cold' },
]

export const marketingdata = [
  { value: 'non-exclusive', label: 'Non Exclusive' },
  { value: 'exclusive', label: 'Exclusive' },
]

export const unusualTransaction = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
]

export const builtUpAreaUnitOptions = [
  { value: 'sqft', label: 'ft²' },
  { value: 'sqyd', label: 'yd²' },
  { value: 'sqm', label: 'm²' },
  { value: 'a', label: 'a' },
  { value: 'ha', label: 'ha' },
  { value: 'sqkm', label: 'km²' },
  { value: 'sqmi', label: 'mi²' },
]

export const tenureData = [
  [
    { value: '30', label: '30-year Leasehold' },
    { value: '60', label: '60-year Leasehold' },
    { value: '99', label: '99-year Leasehold' },
    { value: '103', label: '103-year Leasehold' },
    { value: '999', label: '999-year Leasehold' },
    { value: '9999', label: '9999-year Leasehold' },
    { value: '10000', label: 'Freehold' },
    { value: '10001', label: 'Lease in Perpetuity' },
  ],
]

export const propertyConditionOptions = [
  {
    label: '',
    value: '',
  },
  {
    label: '30-year Leasehold',
    value: '30',
  },
  {
    label: '60-year Leasehold',
    value: '60',
  },
  {
    label: '99-year Leasehold',
    value: '99',
  },
  {
    label: '103-year Leasehold',
    value: '103',
  },
  {
    label: '999-year Leasehold',
    value: '999',
  },
  {
    label: '9999-year Leasehold',
    value: '9999',
  },
  {
    label: 'Freehold',
    value: '10000',
  },
  {
    label: 'Lease in Perpetuity',
    value: '10001',
  },
]
export const formatter = value => {
  if (!value) return ''
  let formatValue = `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return formatValue + '.00'
}

export const parser = value => {
  if (!value) return ''
  return value.replace(/\$\s?|(,*)/g, '')
}

export const transactionFormSchema = type => [
  {
    SALE: {
      fields: [
        {
          name: 'propertyAddress',
          label: 'Property Address',
          type: 'input',
          rules: [
            { required: true, message: 'Please enter property address!' },
          ],
        },

        {
          name: 'propertyType',
          label: 'Property Type',
          type: 'select',
          options: propertTypeData,
          rules: [{ required: true, message: 'Please select property type!' }],
        },
        {
          name: 'representing',
          label: 'Representing',
          type: 'select',
          options: representingData[type],
          rules: [{ required: true, message: 'Please select representing!' }],
          initialValue: 'seller',
        },
        {
          name: 'marketingRights',
          label: 'Marketing Rights',
          type: 'radio',
          options: marketingdata,
          rules: [
            { required: true, message: 'Please select marketing rights!' },
          ],
          initialValue: 'non-exclusive',
        },
        {
          name: 'currency',
          label: 'Currency',
          type: 'select',
          options: currenyData,
          rules: [{ required: false, message: 'Please select currency!' }],
        },
        {
          name: 'salePrice',
          label: 'Sale Price',
          type: 'inputNumber',
          rules: [{ required: false, message: 'Please enter sale price!' }],
        },
        {
          name: 'valuationPrice',
          label: 'Valuation Price',
          type: 'inputNumber',
          rules: [
            { required: false, message: 'Please enter valuation price!' },
          ],
        },
        {
          name: 'optionMoney',
          label: 'Option Money',
          type: 'inputNumber',
          rules: [{ required: false, message: 'Please enter option money!' }],
        },
        {
          name: 'letterOfOfferDate',
          label: 'Letter of Intent Date',
          type: 'date',
          rules: [
            {
              required: false,
              message: 'Please select letter of intent date!',
            },
          ],
        },
        {
          name: 'purchaseDate',
          label: 'Option to Purchase Date',
          type: 'date',
          rules: [
            {
              required: false,
              message: 'Please select option to purchase date!',
            },
          ],
        },
        {
          name: 'exerciseDate',
          label: 'Opinion Exercise Date',
          type: 'date',
          rules: [
            {
              required: false,
              message: 'Please select opinion exercise date!',
            },
          ],
        },
        {
          name: 'completionDate',
          label: 'Estimated Completion Date',
          type: 'date',
          rules: [
            {
              required: false,
              message: 'Please select estimated completion date!',
            },
          ],
        },
        {
          name: 'executionDate',
          label: 'SPA Execution Date',
          type: 'date',
          rules: [
            { required: false, message: 'Please select SPA execution date!' },
          ],
        },
        {
          name: 'nameAndNricOfPeps',
          label: 'Name and NRIC of PEPs',
          type: 'textarea',
          rules: [{ required: true, message: 'Please fill this fields!' }],
        },
        {
          name: 'beneficiaryNamesAndNric',
          label: 'Beneficiary Names and NRICs',
          type: 'textarea',
          rules: [{ required: true, message: 'Please fill this fields!' }],
        },
        {
          name: 'unusualTransaction',
          label: 'This a high risk or unusual transaction',
          type: 'select',
          options: unusualTransaction,
          rules: [
            { required: true, message: 'Please select unusual transaction!' },
          ],
        },
        // {
        //   name: 'billingAddress',
        //   label: 'Billing Address',
        //   type: 'textarea',
        //   rules: [{ required: true, message: 'Please enter billing address!' }],
        // },
      ],
    },
    PROJECT_SALE: {
      fields: [
        {
          name: 'propertyAddress',
          label: 'Property Address',
          type: 'input',
          rules: [
            { required: true, message: 'Please enter property address!' },
          ],
        },
        {
          name: 'marketingRights',
          label: 'Marketing Rights',
          type: 'radio',
          options: marketingdata,
          rules: [
            { required: true, message: 'Please select marketing rights!' },
          ],
          initialValue: 'non-exclusive',
        },
        {
          name: 'currency',
          label: 'Currency',
          type: 'select',
          options: currenyData,
          rules: [{ required: true, message: 'Please select currency!' }],
        },
        {
          name: 'salePrice',
          label: 'Sale Price',
          type: 'inputNumber',
          rules: [{ required: false, message: 'Please enter sale price!' }],
        },
        {
          name: 'valuationPrice',
          label: 'Valuation Price',
          type: 'inputNumber',
          rules: [
            { required: false, message: 'Please enter valuation price!' },
          ],
        },
        {
          name: 'optionMoney',
          label: 'Option Money',
          type: 'inputNumber',
          rules: [{ required: false, message: 'Please enter option money!' }],
        },
        {
          name: 'letterOfOfferDate',
          label: 'Letter of Intent Date',
          type: 'date',
          rules: [
            {
              required: false,
              message: 'Please select letter of intent date!',
            },
          ],
        },
        {
          name: 'purchaseDate',
          label: 'Option to Purchase Date',
          type: 'date',
          rules: [
            {
              required: false,
              message: 'Please select option to purchase date!',
            },
          ],
        },
        {
          name: 'exerciseDate',
          label: 'Opinion Exercise Date',
          type: 'date',
          rules: [
            {
              required: false,
              message: 'Please select opinion exercise date!',
            },
          ],
        },
        {
          name: 'completionDate',
          label: 'Estimated Completion Date',
          type: 'date',
          rules: [
            {
              required: false,
              message: 'Please select estimated completion date!',
            },
          ],
        },
        {
          name: 'executionDate',
          label: 'SPA Execution Date',
          type: 'date',
          rules: [
            { required: false, message: 'Please select SPA execution date!' },
          ],
        },
        {
          name: 'nameAndNricOfPeps',
          label: 'Name and NRIC of PEPs',
          type: 'textarea',
          rules: [{ required: true, message: 'Please fill this fields!' }],
        },
        {
          name: 'beneficiaryNamesAndNric',
          label: 'Beneficiary Names and NRICs',
          type: 'textarea',
          rules: [{ required: true, message: 'Please fill this fields!' }],
        },
        {
          name: 'unusualTransaction',
          label: 'This a high risk or unusual transaction',
          type: 'select',
          options: unusualTransaction,
          rules: [
            { required: true, message: 'Please select unusual transaction!' },
          ],
        },
        // {
        //   name: 'billingAddress',
        //   label: 'Billing Address',
        //   type: 'textarea',
        //   rules: [{ required: true, message: 'Please enter billing address!' }],
        // },
      ],
    },
    RENTAL: {
      fields: [
        {
          name: 'accomadationType',
          label: 'Accommodation Type',
          type: 'radio',
          options: [
            { value: 'whole unit', label: 'Whole Unit' },
            { value: 'room rental', label: 'Room Rental' },
          ],
          initialValue: 'whole unit',
          rules: [
            { required: true, message: 'Please select accommodation type!' },
          ],
        },
        {
          name: 'propertyAddress',
          label: 'Property Address',
          type: 'input',
          rules: [
            { required: true, message: 'Please enter property address!' },
          ],
        },
        {
          name: 'propertyType',
          label: 'Property Type',
          type: 'select',
          options: propertTypeData,
          rules: [{ required: true, message: 'Please select property type!' }],
        },
        {
          name: 'representing',
          label: 'Representing',
          type: 'select',
          options: representingData[type],
          rules: [{ required: true, message: 'Please select representing!' }],
          initialValue: 'landlord',
        },
        {
          name: 'marketingRights',
          label: 'Marketing Rights',
          type: 'radio',
          options: marketingdata,
          rules: [
            { required: true, message: 'Please select marketing rights!' },
          ],
          initialValue: 'non-exclusive',
        },
        {
          name: 'currency',
          label: 'Currency',
          type: 'select',
          options: currenyData,
          rules: [{ required: true, message: 'Please select currency!' }],
        },
        [
          {
            type: 'double',
            fields: [
              {
                name: 'rentalPrice',
                label: 'Rental Price',
                type: 'inputNumber',
                rules: [
                  { required: false, message: 'Please enter rental price!' },
                ],
              },
              {
                name: 'rentalPriceOption',
                type: 'select',
                options: [
                  { value: 'perWeek', label: 'Per Week' },
                  { value: 'perMonth', label: 'Per Month' },
                  { value: 'perYear', label: 'Per Year' },
                ],
                rules: [
                  { required: false, message: 'Please select an option!' },
                ],
              },
            ],
          },
        ],

        {
          name: 'rentalDeposit',
          label: 'Rental Deposit',
          type: 'inputNumber',
          rules: [{ required: false, message: 'Please enter rental deposit!' }],
        },
        {
          name: 'utilitiesDeposit',
          label: 'Utilities Deposit',
          type: 'inputNumber',
          rules: [
            { required: false, message: 'Please enter utilities deposit!' },
          ],
        },
        {
          name: 'letterOfOfferDate',
          label: 'Letter of Intent Date',
          type: 'date',
          rules: [
            {
              required: false,
              message: 'Please select letter of intent date!',
            },
          ],
        },
        {
          name: 'tenancyAgreementDate',
          label: 'Tenancy Agreement Date',
          type: 'date',
          rules: [
            {
              required: false,
              message: 'Please select tenancy agreement date!',
            },
          ],
        },
        {
          name: 'leaseStart',
          label: 'Lease Start',
          type: 'date',
          rules: [
            { required: false, message: 'Please select lease start date!' },
          ],
        },

        [
          {
            type: 'double',
            fields: [
              {
                name: 'leaseDuration',
                label: 'Lease Duration',
                type: 'inputNumber',
                rules: [
                  { required: false, message: 'Please enter lease duration!' },
                ],
              },
              {
                name: 'leaseDurationOption',
                type: 'select',
                options: [
                  { value: 'month', label: 'Month' },
                  // { value: 'year', label: 'Year' },
                  // { value: 'day', label: 'Day' },
                ],
                rules: [
                  { required: false, message: 'Please select an option!' },
                ],
              },
            ],
          },
        ],
        [
          {
            type: 'double',
            fields: [
              {
                name: 'leaseExpiry',
                label: 'Lease Expiry',
                type: 'date',
                rules: [
                  {
                    required: false,
                    message: 'Please select lease expiry date!',
                  },
                ],
                default: 'small check with these fields set as default',
              },
              {
                name: 'remindMe',
                label: 'Remind Me',
                type: 'select',
                options: [
                  { label: 'Never', value: '' },
                  { label: 'At Start Time', value: '0' },
                  { label: '5 minutes before', value: '5' },
                  { label: '15 minutes before', value: '15' },
                  { label: '30 minutes before', value: '30' },
                  { label: '45 minutes before', value: '45' },
                  { label: '1 hour before', value: '60' },
                  { label: '2 hours before', value: '120' },
                  { label: '3 hours before', value: '180' },
                  { label: '6 hours before', value: '360' },
                  { label: '12 hours before', value: '720' },
                  { label: '1 day before', value: '1440' },
                  { label: '2 days before', value: '2880' },
                  { label: '3 days before', value: '4320' },
                  { label: '4 days before', value: '5760' },
                  { label: '5 days before', value: '7200' },
                  { label: '1 week before', value: '10080' },
                  { label: '2 weeks before', value: '20160' },
                  { label: '3 weeks before', value: '30240' },
                  { label: '1 month before', value: '43200' },
                  { label: '2 months before', value: '86400' },
                  { label: '3 months before', value: '129600' },
                  { label: '4 months before', value: '172800' },
                  { label: '5 months before', value: '216000' },
                  { label: '6 months before', value: '259200' },
                ],
                rules: [
                  { required: false, message: 'Please select an option!' },
                ],
              },
            ],
          },
        ],
        {
          name: 'nameAndNricOfPeps',
          label: 'Name and NRIC of PEPs',
          type: 'textarea',
          rules: [{ required: true, message: 'Please fill this fields!' }],
        },
        {
          name: 'beneficiaryNamesAndNric',
          label: 'Beneficiary Names and NRICs',
          type: 'textarea',
          rules: [{ required: true, message: 'Please fill this fields!' }],
        },
        {
          name: 'unusualTransaction',
          label: 'This a high risk or unusual transaction',
          type: 'select',
          options: unusualTransaction,
          rules: [
            { required: true, message: 'Please select unusual transaction!' },
          ],
        },
        // {
        //   name: 'billingAddress',
        //   label: 'Billing Address',
        //   type: 'textarea',
        //   rules: [{ required: true, message: 'Please enter billing address!' }],
        // },
      ],
    },
    ASSIGNMENT_OF_LEASE: {
      fields: [
        {
          name: 'propertyAddress',
          label: 'Property Address',
          type: 'input',
          rules: [
            { required: true, message: 'Please enter property address!' },
          ],
        },
        {
          name: 'propertyType',
          label: 'Property Type',
          type: 'select',
          options: propertTypeData,
          rules: [{ required: true, message: 'Please select property type!' }],
        },
        {
          name: 'representing',
          label: 'Representing',
          type: 'select',
          options: representingData[type],
          rules: [{ required: true, message: 'Please select representing!' }],
          initialValue: 'Assignee',
        },
        {
          name: 'marketingRights',
          label: 'Marketing Rights',
          type: 'radio',
          options: marketingdata,
          rules: [
            { required: true, message: 'Please select marketing rights!' },
          ],
        },
        {
          name: 'currency',
          label: 'Currency',
          type: 'select',
          options: currenyData,
          rules: [{ required: true, message: 'Please select currency!' }],
        },
        [
          {
            type: 'double',
            fields: [
              {
                name: 'rentalPrice',
                label: 'Rental Price',
                type: 'inputNumber',
                rules: [
                  { required: false, message: 'Please enter rental price!' },
                ],
              },
              {
                name: 'rentalPriceOption',
                type: 'select',
                options: [
                  { value: 'perWeek', label: 'Per Week' },
                  { value: 'perMonth', label: 'Per Month' },
                  { value: 'perYear', label: 'Per Year' },
                ],
                rules: [
                  { required: false, message: 'Please select an option!' },
                ],
              },
            ],
          },
        ],

        {
          name: 'rentalDeposit',
          label: 'Rental Deposit',
          type: 'inputNumber',
          rules: [{ required: false, message: 'Please enter rental deposit!' }],
        },
        {
          name: 'utilitiesDeposit',
          label: 'Utilities Deposit',
          type: 'inputNumber',
          rules: [
            { required: false, message: 'Please enter utilities deposit!' },
          ],
        },
        {
          name: 'letterOfOfferDate',
          label: 'Letter of Intent Date',
          type: 'date',
          rules: [
            {
              required: false,
              message: 'Please select letter of intent date!',
            },
          ],
        },
        {
          name: 'tenancyAgreementDate',
          label: 'Tenancy Agreement Date',
          type: 'date',
          rules: [
            {
              required: false,
              message: 'Please select tenancy agreement date!',
            },
          ],
        },
        {
          name: 'leaseStart',
          label: 'Lease Start',
          type: 'date',
          rules: [
            { required: false, message: 'Please select lease start date!' },
          ],
        },
        [
          {
            type: 'double',
            fields: [
              {
                name: 'leaseDuration',
                label: 'Lease Duration',
                type: 'inputNumber',
                rules: [
                  { required: false, message: 'Please enter lease duration!' },
                ],
              },
              {
                name: 'leaseDurationOption',
                type: 'select',
                options: [
                  { value: 'month', label: 'Month' },
                  // { value: 'year', label: 'Year' },
                  // { value: 'day', label: 'Day' },
                ],
                rules: [
                  { required: false, message: 'Please select an option!' },
                ],
              },
            ],
          },
        ],
        [
          {
            type: 'double',
            fields: [
              {
                name: 'leaseExpiry',
                label: 'Lease Expiry',
                type: 'date',
                rules: [
                  {
                    required: false,
                    message: 'Please select lease expiry date!',
                  },
                ],
                default: 'small check with these fields set as default',
              },
              {
                name: 'remindMe',
                label: 'Remind Me',
                type: 'select',
                options: [
                  { label: 'Never', value: '' },
                  { label: 'At Start Time', value: '0' },
                  { label: '5 minutes before', value: '5' },
                  { label: '15 minutes before', value: '15' },
                  { label: '30 minutes before', value: '30' },
                  { label: '45 minutes before', value: '45' },
                  { label: '1 hour before', value: '60' },
                  { label: '2 hours before', value: '120' },
                  { label: '3 hours before', value: '180' },
                  { label: '6 hours before', value: '360' },
                  { label: '12 hours before', value: '720' },
                  { label: '1 day before', value: '1440' },
                  { label: '2 days before', value: '2880' },
                  { label: '3 days before', value: '4320' },
                  { label: '4 days before', value: '5760' },
                  { label: '5 days before', value: '7200' },
                  { label: '1 week before', value: '10080' },
                  { label: '2 weeks before', value: '20160' },
                  { label: '3 weeks before', value: '30240' },
                  { label: '1 month before', value: '43200' },
                  { label: '2 months before', value: '86400' },
                  { label: '3 months before', value: '129600' },
                  { label: '4 months before', value: '172800' },
                  { label: '5 months before', value: '216000' },
                  { label: '6 months before', value: '259200' },
                ],
                rules: [
                  { required: false, message: 'Please select an option!' },
                ],
              },
            ],
          },
        ],
        {
          name: 'nameAndNricOfPeps',
          label: 'Name and NRIC of PEPs',
          type: 'textarea',
          rules: [{ required: true, message: 'Please fill this fields!' }],
        },
        {
          name: 'beneficiaryNamesAndNric',
          label: 'Beneficiary Names and NRICs',
          type: 'textarea',
          rules: [{ required: true, message: 'Please fill this fields!' }],
        },
        {
          name: 'unusualTransaction',
          label: 'This a high risk or unusual transaction',
          type: 'select',
          options: unusualTransaction,
          rules: [
            { required: true, message: 'Please select unusual transaction!' },
          ],
        },
        // {
        //   name: 'billingAddress',
        //   label: 'Billing Address',
        //   type: 'textarea',
        //   rules: [{ required: true, message: 'Please enter billing address!' }],
        // },
      ],
    },
    SERVICED_APARTMENT: {
      fields: [
        {
          name: 'propertyAddress',
          label: 'Property Address',
          type: 'input',
          rules: [
            { required: true, message: 'Please enter property address!' },
          ],
        },
        {
          name: 'propertyType',
          label: 'Property Type',
          type: 'select',
          options: propertTypeData,
          rules: [{ required: true, message: 'Please select property type!' }],
        },
        {
          name: 'representing',
          label: 'Representing',
          type: 'select',
          options: representingData[type],
          rules: [{ required: true, message: 'Please select representing!' }],
          initialValue: 'landlord',
        },
        {
          name: 'marketingRights',
          label: 'Marketing Rights',
          type: 'radio',
          options: marketingdata,
          rules: [
            { required: true, message: 'Please select marketing rights!' },
          ],
        },
        {
          name: 'currency',
          label: 'Currency',
          type: 'select',
          options: currenyData,
          rules: [{ required: true, message: 'Please select currency!' }],
        },
        [
          {
            type: 'double',
            fields: [
              {
                name: 'rentalPrice',
                label: 'Rental Price',
                type: 'inputNumber',
                rules: [
                  { required: false, message: 'Please enter rental price!' },
                ],
              },
              {
                name: 'rentalPriceOption',
                type: 'select',
                options: [
                  { value: 'perWeek', label: 'Per Week' },
                  { value: 'perMonth', label: 'Per Month' },
                  { value: 'perYear', label: 'Per Year' },
                ],
                rules: [
                  { required: false, message: 'Please select an option!' },
                ],
              },
            ],
          },
        ],
        {
          name: 'rentalDeposit',
          label: 'Rental Deposit',
          type: 'inputNumber',
          rules: [{ required: true, message: 'Please enter rental deposit!' }],
        },
        {
          name: 'utilitiesDeposit',
          label: 'Utilities Deposit',
          type: 'inputNumber',
          rules: [
            { required: true, message: 'Please enter utilities deposit!' },
          ],
        },
        {
          name: 'letterOfOfferDate',
          label: 'Letter of Intent Date',
          type: 'date',
          rules: [
            { required: true, message: 'Please select letter of intent date!' },
          ],
        },
        {
          name: 'tenancyAgreementDate',
          label: 'Tenancy Agreement Date',
          type: 'date',
          rules: [
            {
              required: true,
              message: 'Please select tenancy agreement date!',
            },
          ],
        },
        {
          name: 'leaseStart',
          label: 'Lease Start',
          type: 'date',
          rules: [
            { required: true, message: 'Please select lease start date!' },
          ],
        },
        [
          {
            type: 'double',
            fields: [
              {
                name: 'leaseDuration',
                label: 'Lease Duration',
                type: 'inputNumber',
                rules: [
                  { required: false, message: 'Please enter lease duration!' },
                ],
              },
              {
                name: 'leaseDurationOption',
                type: 'select',
                options: [
                  { value: 'month', label: 'Month' },
                  // { value: 'year', label: 'Year' },
                  // { value: 'day', label: 'Day' },
                ],
                rules: [
                  { required: false, message: 'Please select an option!' },
                ],
              },
            ],
          },
        ],
        [
          {
            type: 'double',
            fields: [
              {
                name: 'leaseExpiry',
                label: 'Lease Expiry',
                type: 'date',
                rules: [
                  {
                    required: false,
                    message: 'Please select lease expiry date!',
                  },
                ],
                default: 'small check with these fields set as default',
              },
              {
                name: 'remindMe',
                label: 'Remind Me',
                type: 'select',
                options: [
                  { label: 'Never', value: '' },
                  { label: 'At Start Time', value: '0' },
                  { label: '5 minutes before', value: '5' },
                  { label: '15 minutes before', value: '15' },
                  { label: '30 minutes before', value: '30' },
                  { label: '45 minutes before', value: '45' },
                  { label: '1 hour before', value: '60' },
                  { label: '2 hours before', value: '120' },
                  { label: '3 hours before', value: '180' },
                  { label: '6 hours before', value: '360' },
                  { label: '12 hours before', value: '720' },
                  { label: '1 day before', value: '1440' },
                  { label: '2 days before', value: '2880' },
                  { label: '3 days before', value: '4320' },
                  { label: '4 days before', value: '5760' },
                  { label: '5 days before', value: '7200' },
                  { label: '1 week before', value: '10080' },
                  { label: '2 weeks before', value: '20160' },
                  { label: '3 weeks before', value: '30240' },
                  { label: '1 month before', value: '43200' },
                  { label: '2 months before', value: '86400' },
                  { label: '3 months before', value: '129600' },
                  { label: '4 months before', value: '172800' },
                  { label: '5 months before', value: '216000' },
                  { label: '6 months before', value: '259200' },
                ],
                rules: [
                  { required: false, message: 'Please select an option!' },
                ],
              },
            ],
          },
        ],
        {
          name: 'nameAndNricOfPeps',
          label: 'Name and NRIC of PEPs',
          type: 'textarea',
          rules: [{ required: true, message: 'Please fill this fields!' }],
        },
        {
          name: 'beneficiaryNamesAndNric',
          label: 'Beneficiary Names and NRICs',
          type: 'textarea',
          rules: [{ required: true, message: 'Please fill this fields!' }],
        },
        {
          name: 'unusualTransaction',
          label: 'This a high risk or unusual transaction',
          type: 'select',
          options: unusualTransaction,
          rules: [
            { required: true, message: 'Please select unusual transaction!' },
          ],
        },
        // {
        //   name: 'billingAddress',
        //   label: 'Billing Address',
        //   type: 'textarea',
        //   rules: [{ required: true, message: 'Please enter billing address!' }],
        // },
      ],
    },
    BANK_REFERRAL: {
      fields: [
        // {
        //   name: 'transactionHasRelatedProperty',
        //   label: 'Transaction has a related property',
        //   type: 'checkbox',
        //   rules: [{ required: false }],
        // },
        {
          name: 'currency',
          label: 'Currency',
          type: 'select',
          options: currenyData,
          rules: [{ required: true, message: 'Please select currency!' }],
        },
        {
          name: 'transactionAmount',
          label: 'Transaction Amount',
          type: 'inputNumber',
          rules: [
            { required: true, message: 'Please enter transaction amount!' },
          ],
          parser,
          formatter,
        },

        {
          name: 'nameAndNricOfPeps',
          label: 'Name and NRIC of PEPs',
          type: 'textarea',
          rules: [{ required: true, message: 'Please fill this fields!' }],
        },
        {
          name: 'beneficiaryNamesAndNric',
          label: 'Beneficiary Names and NRICs',
          type: 'textarea',
          rules: [{ required: true, message: 'Please fill this fields!' }],
        },
        {
          name: 'unusualTransaction',
          label: 'This a high risk or unusual transaction',
          type: 'select',
          options: unusualTransaction,
          rules: [
            { required: true, message: 'Please select unusual transaction!' },
          ],
        },

      ],
    },
    PROPERTY_MANAGEMENT: {
      fields: [
        {
          name: 'propertyAddress',
          label: 'Property Address',
          type: 'input',
          rules: [
            { required: true, message: 'Please enter property address!' },
          ],
        },
        {
          name: 'propertyType',
          label: 'Property Type',
          type: 'select',
          options: propertTypeData,
          rules: [{ required: true, message: 'Please select property type!' }],
        },
        {
          name: 'representing',
          label: 'Representing',
          type: 'select',
          options: representingData[type],
          rules: [{ required: true, message: 'Please select representing!' }],
          initialValue: 'landlord',
        },
        {
          name: 'currency',
          label: 'Currency',
          type: 'select',
          options: currenyData,
          rules: [{ required: true, message: 'Please select currency!' }],
          initialValue: 'SGD',
        },
        {
          name: 'transactionAmount',
          label: 'Transaction Amount',
          type: 'inputNumber',
          rules: [
            { required: true, message: 'Please enter transaction amount!' },
          ],
          parser,
          formatter,
        },
        {
          name: 'nameAndNricOfPeps',
          label: 'Name and NRIC of PEPs',
          type: 'textarea',
          rules: [{ required: true, message: 'Please fill this fields!' }],
        },
        {
          name: 'beneficiaryNamesAndNric',
          label: 'Beneficiary Names and NRICs',
          type: 'textarea',
          rules: [{ required: true, message: 'Please fill this fields!' }],
        },
        {
          name: 'unusualTransaction',
          label: 'This a high risk or unusual transaction',
          type: 'select',
          options: unusualTransaction,
          rules: [
            { required: true, message: 'Please select unusual transaction!' },
          ],
        },

      ],
    },
    OTHER: {
      fields: [
        // {
        //   name: 'transactionHasRelatedProperty',
        //   label: 'Transaction has a related property',
        //   type: 'checkbox',
        //   rules: [{ required: false }],
        // },
        {
          name: 'bankReferralName',
          label: 'Bank Referral',
          type: 'input',
          rules: [
            { required: false, message: 'Please enter property address!' },
          ],
        },
        {
          name: 'propertyAddress',
          label: 'Property Address',
          type: 'input',
          rules: [
            { required: true, message: 'Please enter property address!' },
          ],
        },
        {
          name: 'currency',
          label: 'Currency',
          type: 'select',
          options: currenyData,
          rules: [{ required: true, message: 'Please select currency!' }],
          initialValue: 'SGD',
        },
        {
          name: 'transactionAmount',
          label: 'Transaction Amount',
          type: 'inputNumber',
          rules: [
            { required: true, message: 'Please enter transaction amount!' },
          ],
          parser,
          formatter,
        },
        {
          name: 'nameAndNricOfPeps',
          label: 'Name and NRIC of PEPs',
          type: 'textarea',
          rules: [{ required: true, message: 'Please fill this fields!' }],
        },
        {
          name: 'beneficiaryNamesAndNric',
          label: 'Beneficiary Names and NRICs',
          type: 'textarea',
          rules: [{ required: true, message: 'Please fill this fields!' }],
        },
        {
          name: 'unusualTransaction',
          label: 'This a high risk or unusual transaction',
          type: 'select',
          options: unusualTransaction,
          rules: [
            { required: true, message: 'Please select unusual transaction!' },
          ],
        },
        // {
        //   name: 'billingAddress',
        //   label: 'Billing Address',
        //   type: 'textarea',
        //   rules: [{ required: true, message: 'Please enter billing address!' }],
        // },
      ],
    },
  },
]

export const dropdownHeaderMenuItem = () => [
  {
    key: 'profile',
    label: 'Profile',
    icon: <IconUserSquare size={18} />,
  },

  {
    key: 'logout',
    danger: true,
    label: 'Logout',
    icon: <IconLogout size={18} />,
  },
]

export const reminderData = [
  { label: 'Never', value: 'never' },
  { label: 'At Start Time', value: '0' },
  { label: '5 minutes before', value: '5' },
  { label: '15 minutes before', value: '15' },
  { label: '30 minutes before', value: '30' },
  { label: '45 minutes before', value: '45' },
  { label: '1 hour before', value: '60' },
  { label: '2 hours before', value: '120' },
  { label: '3 hours before', value: '180' },
  { label: '6 hours before', value: '360' },
  { label: '12 hours before', value: '720' },
  { label: '1 day before', value: '1440' },
  { label: '2 days before', value: '2880' },
  { label: '3 days before', value: '4320' },
  { label: '4 days before', value: '5760' },
  { label: '5 days before', value: '7200' },
  { label: '1 week before', value: '10080' },
  { label: '2 weeks before', value: '20160' },
  { label: '3 weeks before', value: '30240' },
  { label: '1 month before', value: '43200' },
  { label: '2 months before', value: '86400' },
  { label: '3 months before', value: '129600' },
  { label: '4 months before', value: '172800' },
  { label: '5 months before', value: '216000' },
  { label: '6 months before', value: '259200' },
]

// export const fileItems = [{
//   'name': 'Documents',
//   'isDirectory': true,
//   'items': [{
//       'name': 'Projects',
//       'isDirectory': true,
//       'items': [{
//           'name': 'About.rtf',
//           'isDirectory': false,
//           'size': 1024
//       }, {
//           'name': 'Passwords.rtf',
//           'isDirectory': false,
//           'size': 2048
//       }]
//   }, {
//       'name': 'About.xml',
//       'isDirectory': false,
//       'size': 1024
//   }]
// }];

export const fileItems = [
  {
    name: 'Documents',
    isDirectory: true,
    category: 'Work',
    icon: 'icon/folder.png',
    items: [
      {
        name: 'Projects',
        isDirectory: true,
        category: 'Work',
        icon: 'icon/folder.png',
        items: [
          {
            name: 'About.rtf',
            isDirectory: false,
            icon: 'icon/rtf.png',
            size: 1024,
          },
          {
            name: 'Passwords.rtf',
            isDirectory: false,
            category: 'Important',
            icon: 'icon/rtf.png',
            size: 2048,
          },
        ],
      },
      {
        name: 'About.xml',
        isDirectory: false,
        icon: 'icon/rtf.png',
        size: 1024,
      },
      {
        name: 'Managers.rtf',
        isDirectory: false,
        icon: 'icon/rtf.png',
        size: 2048,
      },
      {
        name: 'ToDo.txt',
        isDirectory: false,
        icon: 'icon/txt-file.png',
        size: 3072,
      },
    ],
  },
  {
    name: 'Images',
    isDirectory: true,
    category: 'Home',
    icon: 'icon/folder.png',
    items: [
      {
        name: 'logo.png',
        isDirectory: false,
        size: 20480,
      },
      {
        name: 'banner.gif',
        isDirectory: false,
        size: 10240,
      },
    ],
  },
  {
    name: 'System',
    isDirectory: true,
    category: 'Important',
    icon: 'icon/folder.png',
    items: [
      {
        name: 'Employees.txt',
        isDirectory: false,
        category: 'Important',
        icon: 'icon/txt-file.png',
        size: 3072,
      },
      {
        name: 'PasswordList.txt',
        isDirectory: false,
        category: 'Important',
        icon: 'icon/txt-file.png',
        size: 5120,
      },
    ],
  },
  {
    name: 'Description.rtf',
    isDirectory: false,
    size: 1024,
    icon: 'icon/rtf.png',
  },
  {
    name: 'Description.txt',
    isDirectory: false,
    size: 2048,
    icon: 'icon/txt-file.png',
  },
]
const fileExtensions = {
  'Text Document': '.txt',
  'RTF Document': '.rtf',
  Spreadsheet: '.xls',
}
const categories = ['Work', 'Important', 'Home', 'None']
export function getItemInfo(name) {
  const extension = fileExtensions[name]
  const category = extension ?? categories.find(cat => cat === name)
  return {
    extension,
    category,
  }
}

export const beingForData = [
  {
    label: <Typography.Text strong>Brokerage Services</Typography.Text>,
    title: 'Brokerage Services',
    options: [
      {
        label: <span>Brokerage Commission</span>,
        value: 'Brokerage Commission',
      },
      {
        label: <span>Referral</span>,
        value: 'Referral',
      },
    ],
  },
  {
    label: <Typography.Text strong>Other Services</Typography.Text>,
    title: 'Other Services',
    options: [
      {
        label: <span>Other</span>,
        value: 'Other',
      },
    ],
  },
]

export const checkRentalPriceOption = value => {
  let data
  switch (value) {
    case 'perMonth':
      data = 'Per Month'
      break
    case 'perWeek':
      data = 'Per Week'
      break
    case 'perYear':
      data = 'Per Year'
      break
    case 'transactionAmount':
      data = 'Transaction Amount'
      break
    default:
      data = 'Sale Price'
      break
  }

  return data
}
