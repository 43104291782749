import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AuthenticatedRoute, NonAuthenticatedRoute } from './routes/routeHandle'
import publicRoutes from './routes/publicRoutes'
import { privateRoutes } from './routes/privateRoutes'

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<NonAuthenticatedRoute />}>
          {publicRoutes.map(route => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Route>
        <Route element={<AuthenticatedRoute />}>
          {privateRoutes.map(route => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
