import { lazy } from 'react'
const Transactions = lazy(() => import('../pages/transactions'))
const Profile = lazy(() => import('../pages/profile'))
const Detail = lazy(() => import('../pages/transactions/Detail'))
const ManageTransaction = lazy(() =>
  import('../pages/transactions/ManageTransaction')
)
const Contacts = lazy(() => import('../pages/contacts/contact'))
const ManageContact = lazy(() => import('../pages/contacts/ManageContact'))
const Calendar = lazy(() => import('../pages/Calendar'))
const Documents = lazy(() => import('../pages/Documents'))
const Reports = lazy(() => import('../pages/Reports'))
const ContactDetail = lazy(() => import('../pages/contacts/ContactDetail'))
const Accounting = lazy(() => import('../pages/accounting/Accounting'))
const InvoiceDetail = lazy(() =>
  import('../pages/accounting/invoice/InvoiceDetail')
)
const DebitNoteDetail = lazy(() =>
  import('../pages/accounting/debitNote/DebitNoteDetail')
)
const ReceiptDetail = lazy(() =>
  import('../pages/accounting/receipt/ReceiptDetail')
)
const CreditNoteDetail = lazy(() =>
  import('../pages/accounting/creditNote/CreditNoteDetail')
)
const CommissionPayableDetail = lazy(() =>
  import('../pages/accounting/comissionpayable/CommissionPayableDetail')
)
const PaymentVoucherDetail = lazy(() =>
  import('../pages/accounting/paymentVoucher/PaymentVoucherDetail')
)
const Adminstration = lazy(() =>
  import('../pages/adminstration/Adminstration')
)
const UserDetail = lazy(() =>
  import('../pages/adminstration/user/UserDetail')
)
const UpdateTransaction = lazy(() =>
  import('../pages/transactions/update/UpdateTransaction')
)

export const privateRoutes = [
  {
    path: '/profile',
    element: <Profile />,
    ability: ['agent', 'sale'],
  },
  {
    path: '/transactions',
    element: <Transactions />,
    ability: ['agent', 'sale'],
  },
  {
    path: '/transaction/add',
    element: <ManageTransaction />,
    ability: ['agent', 'sale'],
  },
  {
    path: '/transaction/:id',
    element: <Detail />,
    ability: ['agent', 'sale'],
  },
  {
    path: '/transaction/update/:id',
    element: <UpdateTransaction />,
    ability: ['agent', 'sale'],
  },
  {
    path: '/contacts',
    element: <Contacts />,
    ability: ['agent', 'sale'],
  },
  {
    path: '/contact/add',
    element: <ManageContact />,
    ability: ['agent', 'sale'],
  },
  {
    path: '/contact/edit/:id',
    element: <ManageContact />,
    ability: ['agent', 'sale'],
  },
  {
    path: '/contact/:id',
    element: <ContactDetail />,
    ability: ['agent', 'sale'],
  },
  {
    path: '/calendar',
    element: <Calendar />,
    ability: ['agent', 'sale'],
  },
  {
    path: '/documents',
    element: <Documents />,
    ability: ['agent', 'sale'],
  },
  {
    path: '/reports',
    element: <Reports />,
    ability: ['agent', 'sale'],
  },
  {
    path: '/accounting',
    element: <Accounting />,
    ability: ['agent', 'sale'],
  },
  {
    path: '/adminstration',
    element: <Adminstration />,
    ability: ['agent', 'sale'],
  },
  {
    path: '/invoice/:id',
    element: <InvoiceDetail />,
    ability: ['agent', 'sale'],
  },
  {
    path: '/debit-note/:id',
    element: <DebitNoteDetail />,
    ability: ['agent', 'sale'],
  },
  {
    path: '/receipt/:id',
    element: <ReceiptDetail />,
    ability: ['agent', 'sale'],
  },
  {
    path: '/credit-note/:id',
    element: <CreditNoteDetail />,
    ability: ['agent', 'sale'],
  },
  {
    path: '/commission-payable/:id',
    element: <CommissionPayableDetail />,
    ability: ['agent', 'sale'],
  },
  {
    path: '/payment-voucher/:id',
    element: <PaymentVoucherDetail />,
    ability: ['agent', 'sale'],
  },
  {
    path: '/user/:id',
    element: <UserDetail />,
    ability: ['agent', 'sale'],
  },
]
