import { Navigate, Outlet } from 'react-router-dom'
import MainLayout from '../layouts/MainLayout'
import { useEffect } from 'react'
import { jwtDecode } from 'jwt-decode'
import { message } from 'antd'
import Cookies from 'js-cookie'
import { logoutApi } from '../utils/apis/authApi'

export const AuthenticatedRoute = () => {
  useEffect(() => {
    checkToken()
  }, [])

  const checkToken = () => {
    const token = Cookies.get('access_token')

    if (token) {
      try {
        const decoded = jwtDecode(token)
        const currentTime = Date.now() / 1000

        if (decoded.exp < currentTime) {
          logoutApi()
          message.open({
            type: 'error',
            content: 'Unauthenticated!',
          })
        } else {
        }
      } catch (error) {
        console.log('Error decoding token:', error)
      }
    } else {
      logoutApi()
    }
  }

  const isAuthenticated = Cookies.get('access_token') ? true : false

  if (!isAuthenticated) {
    return <Navigate to="/login" />
  }

  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  )
}

export const NonAuthenticatedRoute = () => {
  const isAuthenticated = Cookies.get('access_token') ? true : false

  if (isAuthenticated) {
    return <Navigate to="/transactions" />
  }

  return <Outlet />
}
