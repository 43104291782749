import api from '../api'
import Cookies from 'js-cookie'

export const loginApi = async userData => {
  return await api.post('/auth/login', userData)
}

export const registerApi = async userData => {
  return await api.post('/auth/register', userData)
}

export const forgetPasswordApi = async userData => {
  return await api.post('/auth/forget-password', userData)
}

export const changeRoleApi = async roleData => {
  return await api.put(`/auth/change-role/${roleData.userId}`, {
    role: roleData.role,
  })
}

export const logoutApi = () => {
  Cookies.remove('access_token')
  Cookies.remove('user')
  Cookies.remove('isAuthenticated')
  Cookies.remove('role')
}
