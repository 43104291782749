import { lazy } from 'react'
const Login = lazy(() => import('../pages/Login'))
const Register = lazy(() => import('../pages/Register'))
const ForgetPassword = lazy(() => import('../pages/ForgetPassword'))
const publicRoutes = [
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/register',
    element: <Register />,
  },
  {
    path: '/forget-password',
    element: <ForgetPassword />,
  },
  {
    path: '*',
    element: <Login />,
  },
]

export default publicRoutes
