import React, { startTransition, Suspense, useEffect, useState } from 'react'
import { AntDesignOutlined } from '@ant-design/icons'
import {
  Avatar,
  ConfigProvider,
  Drawer,
  Dropdown,
  Flex,
  Image,
  Layout,
  Menu,
  Spin,
  Switch,
  Typography,
  message,
} from 'antd'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import logo from '../assets/logo.png'
import { dropdownHeaderMenuItem } from '../utils/data'
import { changeRoleApi, logoutApi } from '../utils/apis/authApi'
import Cookies from 'js-cookie'
import { handleApiError, handleApiSuccess } from '../helpers/CONSTANT'
import useIsMobile from '../hooks/useIsMobile'
import { SlMenu } from 'react-icons/sl'

const { Header, Content } = Layout

const MainLayout = () => {
  const navigate = useNavigate()
  const user = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : {}
  const [loadingRole, setLoadingRole] = useState(false)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const widthCheck = useIsMobile()
  const items = dropdownHeaderMenuItem()
  const [role, setRole] = useState(
    Cookies.get('role') ? Cookies.get('role') : null
  )

  useEffect(() => {
    if (widthCheck >= 1240) {
      setMobileMenuOpen(false)
    }
  }, [widthCheck])

  const handleRoleChange = async role => {
    setRole(role === 'agency' ? 'salesperson' : 'agency')
    setLoadingRole(true)
    try {
      const response = await changeRoleApi({
        userId: user._id,
        role: role ? 'agency' : 'salesperson',
      })
      const { user: userData, access_token } = response.data
      Cookies.set('user', JSON.stringify(userData))
      Cookies.set('role', userData?.role)
      Cookies.set('access_token', access_token)
      setRole(userData?.role)
      handleApiSuccess(response)
      setLoadingRole(false)
      window.location.reload()
    } catch (error) {
      handleApiError(error)
      setLoadingRole(false)
    }
  }

  const handleMenuChange = ({ key }) => {
    startTransition(() => {
      if (key === 'logout') {
        logoutApi()
        navigate('/login')
        message.open({
          type: 'info',
          content: 'Logout Successfully',
        })
        return
      } else {
        navigate('/profile')
      }
    })
  }

  const menuData = [
    {
      key: 'transactions',
      label: (
        <Link to={'/transactions'} style={{ maxWidth: '130px', width: '100%' }}>
          <Typography.Text strong>Transactions</Typography.Text>
        </Link>
      ),
      role: ['salesperson', 'agency'],
    },
    {
      key: 'contacts',

      label: (
        <Link to={'/contacts'}>
          <Typography.Text strong>Contacts</Typography.Text>
        </Link>
      ),
      role: ['salesperson', 'agency'],
    },
    {
      key: 'calendar',

      label: (
        <Link to={'/calendar'}>
          <Typography.Text strong>Calendar</Typography.Text>
        </Link>
      ),
      role: ['salesperson', 'agency'],
    },
    {
      key: 'accounting',
      label: (
        <Link to={'/accounting'}>
          <Typography.Text strong>Accounting</Typography.Text>
        </Link>
      ),
      role: ['salesperson', 'agency'],
    },
    {
      key: 'adminstration',
      label: (
        <Link to={'/adminstration'}>
          <Typography.Text strong>Adminstration</Typography.Text>
        </Link>
      ),
      role: ['agency'],
    },
    {
      key: 'documents',
      label: (
        <Link to={'/documents'}>
          <Typography.Text strong>Documents</Typography.Text>
        </Link>
      ),
      role: ['salesperson', 'agency'],
    },
    // {
    //   key: 'reports',
    //   label: (
    //     <Flex
    //       gap={'small'}
    //       align="center"
    //       justify="center"
    //       style={{ height: '60px' }}
    //     >
    //       <Typography.Text strong>Reports</Typography.Text>
    //       <FlagIcon width={18} style={{ marginBottom: '-2px', color: '#444' }} />
    //     </Flex>
    //   ),
    // },
  ]

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true)
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadius: 5,
          borderRadiusLG: 5,
          fontSize: 15,
          colorPrimary: '#002975',
        },
        components: {
          Layout: {
            headerBg: '#fff',
          },
          Form: {
            fontSize: '16px',
            colorBorder: '#D9D9D9',
          },
          Table: {
            headerBg: '#EEEEEE',
            headerSplitColor: '#E3E3E3',
            headerBorderRadius: '10px',
            headerColor: '#373737',
            colorText: '#373737',
          },
        },
      }}
    >
      <Layout className="min-h-100">
        <Header
          style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            height: 'auto',
          }}
        >
          <Flex align="center" flex={1} gap={'40px'}>
            <Link to={'/transactions'}>
              <Image preview={false} width={140} src={logo} alt="logo" />
            </Link>
            {widthCheck >= 1240 ? (
              <Menu
                theme="light"
                mode="horizontal"
                defaultSelectedKeys={['transactions']}
                items={menuData.filter(item => item.role.includes(user?.role))}
                style={{ flex: 1, minWidth: 0 }}
              />
            ) : null}
          </Flex>

          <Flex align="center" gap={'small'} justify="end" wrap="wrap">
            <Flex gap={'large'} align="center">
              {widthCheck <= 1240 ? (
                <SlMenu
                  onClick={handleMobileMenuOpen}
                  size={20}
                  style={{ cursor: 'pointer' }}
                />
              ) : null}
              {user?.parentId ? null : (
                <Switch
                  disabled={loadingRole}
                  onChange={handleRoleChange}
                  value={role}
                  checked={role === 'agency'}
                  checkedChildren="Agency"
                  unCheckedChildren="Sales Person"
                />
              )}
            </Flex>
            <Flex gap={'small'} justify='flex-end' align="center" className='responsive-width '>
              <Flex vertical align="end">
                <Typography.Text strong>
                  {user?.name || user?.username}
                </Typography.Text>
                <Typography.Text type="secondary">{user?.role}</Typography.Text>
              </Flex>
              <Dropdown
                menu={{
                  items,
                  onClick: handleMenuChange,
                }}
                trigger={['click']}
                overlayStyle={{ width: '100%', maxWidth: '180px' }}
              >
                <Avatar
                  size={{
                    xs: 24,
                    sm: 32,
                    md: 40,
                    lg: 80,
                  }}
                  shape="square"
                  icon={
                    user?.photo ? (
                      <Image
                        preview={false}
                        width={60}
                        src={
                          process.env.REACT_APP_MEDIA_URL + '/' + user?.photo
                        }
                        alt="photo"
                      />
                    ) : (
                      <AntDesignOutlined />
                    )
                  }
                />
              </Dropdown>
            </Flex>
          </Flex>
        </Header>
        <Suspense
          fallback={
            <Flex
              align="center"
              justify="center"
              style={{ minHeight: 'calc(100vh - 100px)' }}
            >
              <Spin size="large" />
            </Flex>
          }
        >
          <Layout
            style={{
              padding: '0 24px 24px',
            }}
          >
            {/* <Breadcrumb
            style={{
              margin: '16px 0',
            }}
          >
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>List</Breadcrumb.Item>
            <Breadcrumb.Item>
              {location.pathname === '/transactions'
                ? 'Transactions'
                : location.pathname === '/contacts'
                ? 'Contacts'
                : location.pathname === '/calendar'
                ? 'Calendar'
                : location.pathname === '/documents'
                ? 'Documents'
                : 'Reports'}
            </Breadcrumb.Item>
          </Breadcrumb> */}
            <Content
              style={{
                padding: '40px 20px',
                margin: 0,
                // height: 'calc(100vh - 100px)',
                overflow: 'auto',
              }}
            >
              <Outlet />
            </Content>
          </Layout>
        </Suspense>

        <Drawer
          width={240}
          title="Menu"
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
        >
          <Menu
            theme="light"
            mode="vertical"
            defaultSelectedKeys={['transactions']}
            items={menuData.filter(item => item.role.includes(user?.role))}
            style={{ flex: 1, minWidth: 0 }}
          />
        </Drawer>
      </Layout>
    </ConfigProvider>
  )
}
export default MainLayout
